<template>
  <section class="my-5">
    <div class="mb-5 text-center">
      <h1>Create Fiat Currency</h1>
      <div>
        <small
          >Currencies added here are available for users to use in creating a
          wallet</small
        >
      </div>
    </div>
    <CreateFiatCurrencyForm />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CreateFiatCurrencyForm from "@/views/currency/constituent/CreateFiatCurrencyForm.vue";

export default defineComponent({
  name: "CreateFiatCurrency",
  components: {
    CreateFiatCurrencyForm,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped></style>
