<template>
  <div class="row justify-content-center my-5">
    <div class="card col-12 col-md-6 col-lg-4">
      <DualRingLoader :loading="loading">
        <div class="card-body">
          <form class @submit.prevent="Submit">
            <div class="mb-3">
              <div>
                <label for="selector" class="form-label">Select Currency</label>
                <Suspense>
                  <template #default>
                    <InputWrapper :errors="formError.code">
                      <ValidFiatCurrencySelector id="selector" v-model="formData.code" required />
                    </InputWrapper>
                  </template>
                  <template #fallback>Loading card...</template>
                </Suspense>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="name" class="form-label">Currency Name</label>
                <InputWrapper :errors="formError.name">
                  <input
                    v-model="formData.name"
                    placeholder="Currency Readable Name"
                    class="form-control"
                    id="name"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="we_buy" class="form-label">We Buy $ at</label>
                <InputWrapper :errors="formError.we_buy">
                  <input
                    v-model.number="formData.we_buy"
                    type="number"
                    placeholder="0.00"
                    class="form-control"
                    id="we_buy"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="we_sell" class="form-label">We Sell $ at</label>
                <InputWrapper :errors="formError.we_sell">
                  <input
                    v-model.number="formData.we_sell"
                    type="number"
                    placeholder="0.00"
                    class="form-control"
                    id="we_sell"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-dark">Create Fiat Currency</button>
            </div>
          </form>
        </div>
      </DualRingLoader>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { apiPost } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { ShowSuccess } from "@/utils/notify";
import ValidFiatCurrencySelector from "@/components/selector/ValidFiatCurrencySelector.vue";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "CreateFiatCurrencyForm",
  components: { InputWrapper, ValidFiatCurrencySelector, DualRingLoader },
  emits: ["save"],
  setup() {
    const formData = ref({
      code: null,
      name: null,
      we_buy: null,
      we_sell: null,
    });
    const formError = ref({});
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      formError.value = {};
      try {
        await apiPost(`/admin/fiats`, formData.value);
        ShowSuccess("Fiats Created Successfully", () => {
          formData.value = {
            code: null,
            name: null,
            we_buy: null,
            we_sell: null,
          };
        });
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create entry: " + e.message,
        }).then();
      }
      loading.value = false;
    }
    return {
      Submit,
      formData,
      formError,
      loading,
    };
  },
});
</script>

<style scoped></style>
