
import { defineComponent } from "vue";
import CreateFiatCurrencyForm from "@/views/currency/constituent/CreateFiatCurrencyForm.vue";

export default defineComponent({
  name: "CreateFiatCurrency",
  components: {
    CreateFiatCurrencyForm,
  },
  setup() {
    return {};
  },
});
