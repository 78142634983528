
import { ref, watch, defineComponent } from "vue";
import { apiGet } from "@/api";

export default defineComponent({
  name: "ValidFiatCurrencySelector",
  props: ["value"],
  emits: ["input"],
  async setup(props) {
    const valueCopy = ref(null);
    const currencies = ref([]);
    const loading = ref(false);

    const cur = ref([]);

    watch(
      () => props.value,
      (val) => {
        if (val !== valueCopy.value) {
          valueCopy.value = val;
        }
      },
      {
        immediate: false,
      }
    );

    const gottenFromStorage = sessionStorage.getItem("valid_card_currencies");
    if (gottenFromStorage) {
      currencies.value = JSON.parse(gottenFromStorage);
    } else {
      loading.value = true;
      try {
        const response = await apiGet("/admin/misc/get-valid-fiats");
        currencies.value = response.data.data.fiats;

        sessionStorage.setItem(
          "valid_card_currencies",
          JSON.stringify(currencies.value)
        );
      } catch (e) {
        // Nothing here
      }
      loading.value = false;
    }

    return {
      valueCopy,
      currencies,
      loading,
    };
  },
});
