
import { defineComponent, ref } from "vue";
import { apiPost } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { ShowSuccess } from "@/utils/notify";
import ValidFiatCurrencySelector from "@/components/selector/ValidFiatCurrencySelector.vue";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "CreateFiatCurrencyForm",
  components: { InputWrapper, ValidFiatCurrencySelector, DualRingLoader },
  emits: ["save"],
  setup() {
    const formData = ref({
      code: null,
      name: null,
      we_buy: null,
      we_sell: null,
    });
    const formError = ref({});
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      formError.value = {};
      try {
        await apiPost(`/admin/fiats`, formData.value);
        ShowSuccess("Fiats Created Successfully", () => {
          formData.value = {
            code: null,
            name: null,
            we_buy: null,
            we_sell: null,
          };
        });
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create entry: " + e.message,
        }).then();
      }
      loading.value = false;
    }
    return {
      Submit,
      formData,
      formError,
      loading,
    };
  },
});
